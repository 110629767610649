/* You can add global styles to this file, and also import other style files */
@use "./variable.scss" as *;
@import "primeicons/primeicons.css";
@import "primeflex/primeflex.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito";
  font-size: 15px;
}

// .p-disabled, .p-component:disabled {
//   opacity: 0.5;
// }

body {
  font-size: 14px;
  letter-spacing: 0.1px;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
  gap: 0.6rem;
  display: flex;
}

.page-container {
  background-color: white;
  width: 100%;
  padding: 0 2rem;
  padding-bottom: 2rem;
}

@media (prefers-color-scheme: dark) {
  .page-container {
      background-color: #333;
  }
}

.p-datatable {
  border-radius: 0.7rem 0.7rem 0 0;
}

.p-datatable .p-datatable-wrapper {
  border-radius: 0.7rem 0.7rem 0 0;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 1rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 700;
  white-space: nowrap;
  // background: #f6f5ff;
}

.p-paginator {
  padding: 0.5rem 1rem;
}

.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
}

.p-paginator {
  padding: 0.5rem 1rem;
}

.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  height: 3rem;
  .p-inputtext {
    padding: 1rem 0 1rem 1rem !important;
  }
}

.p-column-filter-row {
  width: 70%;
}

.p-fluid .p-inputtext {
  height: 2.1rem;
  padding: 0.5rem;
  font-size: 0.8rem;
  border-radius: 4px;
  width: 100%;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.5rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding: 0.4rem !important;
}

.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  border-radius: 30%;
  height: 2.3rem;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: rgba(63, 81, 181, 0.12);
  border-color: rgba(63, 81, 181, 0.12);
  background-color: $primary-blue;
  color: #ffffff;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0.8rem !important;
  width: 208px;
}

.hover-bg-light-blue {
  background-color: #ebf0ff;
  border-left: 2px solid $primary-blue;
  color: $primary-blue;
}

.hover-bg-light-green {
  background-color: #ebf0ff;
  border-left: 2px solid #439f6e;
  color: #439f6e;
}

.hover-bg-light-red {
  background-color: #f4c7cd;
  border-left: 2px solid #e71d36;
  color: #e71d36;
}

.list-item {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  padding-left: 1rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.2rem;
  letter-spacing: 0px;
  text-align: left;
  padding-block: 10px;
  cursor: pointer;
}

.dragDrop-list {
  width: 100%;
  border: solid 1px #ccc;
  display: block;
  // background: white;
  border-radius: 4px;
  overflow: hidden;
}

.dragDrop-box {
  padding: 10px;
  border-bottom: solid 1px #ccc;
  // color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: grab;
  // background: white;
  font-size: 0.9rem;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.dragDrop-box:last-child {
  border: none;
}

.dragDrop-list.cdk-drop-list-dragging .dragDrop-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.dragDrop-custom-placeholder {
  background: #ccc;
  border: dotted 3px #999;
  min-height: 3rem;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.p-dialog .p-dialog-content {
  font-size: 0.8rem;
}

.p-inputtext:enabled:focus {
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.p-inputtext:enabled:hover {
  border-color: none !important;
}

.p-button {
  background-color: $primary-blue;
  padding: 0.5rem;
  gap: 0.5rem;
}

.p-button .p-button-label {
  transition-duration: 0.2s;
  font-size: 0.8rem !important;
}

.p-inputtext.ng-dirty.ng-invalid {
  border-color: rgba(0, 0, 0, 0.38);
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: rgba(0, 0, 0, 0.38);
}

.required-label {
  color: red;
  margin-left: 4px;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: rgba(0, 0, 0, 0.38);
}

.p-autocomplete {
  width: 100%;
  // height: 2.4rem;
  .p-inputtext {
    height: 2.4rem;
    padding: 0.5rem;
    font-size: 0.8rem;
    border-radius: 4px;
    width: 100%;
  }

  .p-chip-label{
    font-size: 0.8rem !important;
  }
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  border-color: rgba(0, 0, 0, 0.38);
}

.p-tag {
  padding: 0.25rem 2rem !important;
  border-radius: 4px;
  font-weight: 600;
  font-size: 0.8rem !important;
}

.p-tag.p-tag-danger {
  background-color: #fccccc;
  color: #ff0000;
}

.p-tag.p-tag-success {
  background-color: #ccefcc;
  color: #00ad00;
}

.p-tag.p-tag-warn {
  background-color: #fce0cc;
  color: #ef6500;
}

.p-tag.p-tag-info {
  background-color: #ebf0ff;
  color: $primary-blue;
}

.p-tag-icon,
.p-tag-value,
.p-tag-icon.pi {
  font-size: 0.9rem !important;
}

.p-dropdown {
  height: 2.1rem;
  width: 100%;
}

.p-dropdown .p-inputtext {
  border: 0 none;
  font-size: 0.8rem !important;
  padding: 0.5rem !important;
  border-radius: 4px;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
  border: 1px solid #ccc;
}

.pi {
  top: 8px;
}

.p-checkbox .p-checkbox-box {
  border-color: #ccc;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $primary-blue;
  background: $primary-blue;
}

.p-autocomplete {
  width: 100%;
}

.p-autocomplete-dd .p-autocomplete-multiple-container {
  width: 100%;
  height: 2.3rem;
}

.p-autocomplete-multiple-container {
  width: 100%;
  height: 2.3rem;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
  // border: 1px solid #ccc;
  font-size: 0.9rem;
  padding: 0.5rem;
  width: 100% !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: $primary-blue;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: $primary-blue;
}

.p-dropdown .p-dropdown-clear-icon {
  color: rgba(0, 0, 0, 0.6);
  right: 2.5rem;
  top: 0.5rem;
}

.p-autocomplete
  .p-autocomplete-multiple-container
  .p-autocomplete-input-token
  input {
  padding: 0.5rem !important;
}

// .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
//   padding: 0.5rem !important;
// }

.p-autocomplete:not(.p-disabled).p-focus .p-autocomplete-multiple-container {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border: 1px solid #ccc;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.2rem !important;
  border-radius: 0.5rem;
  margin: 0.3rem;
}

.p-autocomplete
  .p-autocomplete-multiple-container
  .p-autocomplete-token
  .p-autocomplete-token-icon {
  margin-left: 0.5rem !important;
  color: red;
}

.p-autocomplete-token-label {
  font-size: 0.8rem !important;
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  background-color: $light-grey;
  border-radius: 3px;
}

.p-dialog .p-dialog-footer {
  padding: 0.75rem 1.25rem !important;
}

.p-dialog .p-dialog-header {
  padding: 1.25rem !important;
}

.p-dialog .p-dialog-content {
  padding: 0 1.25rem 1.25rem 1.25rem !important;
}

.p-calendar {
  height: 2.1rem;
  width: 100%;
}

p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
  border-color: rgba(0, 0, 0, 0.38);
}

.p-calendar .p-inputtext {
  font-size: 0.8rem !important;
  padding: 0.5rem !important;
}

p-inputnumber,
.p-inputnumber {
  display: inline-flex;
  width: 100%;
}

.p-inputnumber-input {
  font-size: 0.8rem !important;
  padding: 0.5rem !important;
  width: 100%;
}

.p-icon-wrapper {
  top: 27%;
}

.p-dropdown-filter-icon {
  top: 30%;
}

.p-datepicker {
  width: 100% !important;
  height: 2.1rem;
}

.p-datepicker .p-datepicker-header {
  padding: 0.3rem !important;
}

.p-datepicker table {
  font-size: 1rem !important;
  margin: 0.5rem 0 !important;
}

.p-datepicker-input {
  flex: 1 1 auto;
  font-size: 0.8rem !important;
}

.p-select-label {
  font-size: 0.8rem;
}

.p-datepicker table td > span.p-highlight {
  color: $primary-blue;
  background: #3f51b51f;
}

.p-multiselect {
  width: 100%;
  height: 2.1rem;
}

.p-popover:after, .p-popover:before {
  bottom: 100%;
  left: 84% !important;
}

.p-popover {
  min-width: 10%;
}

.p-multiselect .p-multiselect-label {
  font-size: 0.8rem !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $primary-blue;
  background: $primary-blue;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin: 0.5rem !important;
}

.p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-multiselect-filter-icon {
  top: 0.5rem;
}

.p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin: 0.5rem !important;
}

.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none;
}

.p-multiselect-filter-container .p-inputtext {
  height: 2.1rem;
}

.p-multiselect:not(.p-disabled).p-focus {
  border: 1px solid #ccc;
}

.p-tabview .p-tabview-nav li {
  margin-right: 2rem !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: rgba(0, 0, 0, 0);
  color: $primary-blue;
  // background: white;
  padding: 0.5rem 0;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: $primary-blue;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 0.5rem 0 !important;
}

.p-tabview-title {
  font-size: 0.9rem !important;
}

.p-datepicker .p-datepicker-header {
  padding: 0.3rem !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.p-datepicker table {
  font-size: 1rem !important;
  margin: 0.5rem 0 !important;
}

.p-datepicker table td > span.p-highlight {
  color: $primary-blue;
  background: #3f51b51f;
}

.p-sidebar .p-sidebar-header {
  padding: 0.75rem !important;
}

.p-sidebar-header {
  display: flex;
  flex-direction: row-reverse;
}

.pi-spin {
  font-size: 0.9rem;
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 100;
  src: url("./Fonts/NunitoSans_LightItalic.ttf");
}

@font-face {
  font-family: "Nunito";
  font-size: 200;
  font-style: normal;
  src: url("./Fonts/NunitoSans_ExtraLight.ttf");
}

@font-face {
  font-family: "Nunito";
  font-size: 200;
  font-style: italic;
  src: url("./Fonts/NunitoSans_BoldItalic.ttf");
}

@font-face {
  font-family: "Nunito";
  font-weight: 300;
  font-style: normal;
  src: url("./Fonts/NunitoSans_Medium.ttf");
}

@font-face {
  font-family: "Nunito";
  font-weight: 300;
  font-style: italic;
  src: url("./Fonts/NunitoSans_LightItalic.ttf");
}

@font-face {
  font-family: "Nunito";
  font-weight: 400;
  font-style: normal;
  src: url("./Fonts/NunitoSans_Regular.ttf");
}

@font-face {
  font-family: "Nunito";
  font-weight: 500;
  font-style: normal;
  src: url("./Fonts/NunitoSans_Medium.ttf");
}

@font-face {
  font-family: "Nunito";
  font-weight: 500;
  font-style: italic;
  src: url("./Fonts/NunitoSans_BlackItalic.ttf");
}

@font-face {
  font-family: "Nunito";
  font-weight: 600;
  font-style: normal;
  src: url("./Fonts/NunitoSans_SemiBold.ttf");
}

@font-face {
  font-family: "Nunito";
  font-weight: 600;
  font-style: italic;
  src: url("./Fonts/NunitoSans_Italic.ttf");
}

@font-face {
  font-family: "Nunito";
  font-weight: 700;
  font-style: normal;
  src: url("./Fonts/NunitoSans_Bold.ttf");
}
